<template>
  <div class="d-flex flex-column">
    <SideBar v-if="hasNavBar()" />
    <div class="content d-flex flex-column flex-grow-1">
      <parameter-nav v-if="hasParameterNav()" :title="title" :route="route"></parameter-nav>
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
// Fontawesome
import SideBar from "@/components/navbar/NavBar.vue";
import ParameterNav from "@/components/common/nav/ParameterNav.vue";
export default {
  name: "App",
  data() {
    return {
      title: "Default Title",
      route: null,
    }
  },
  methods: {
    hasNavBar() {
      if (!this.$route.name) return false;

      return !(this.$route.meta && this.$route.meta.noNavBar === true);
    },
    hasParameterNav() {
      if (!this.$route.name) return false
      return (this.$route.meta && this.$route.meta.hasParamNavBar === true);
    },
    getTitle() {
      this.title = this.$route.meta.title ?? this.title;
    },
    getRoute() {
      this.route = this.$route.meta.route ?? this.route;
    }
  },
  watch: {
    '$route.meta': {
      immediate: true,
      handler() {
        this.getTitle();
        this.getRoute();
      }
    }
  },
  components: {
    ParameterNav,
    SideBar,
  },
};
</script>

<style scoped lang="scss">
.content {
  height: 100vh;
}
</style>

<style lang="scss">
  .col-0-5 {
    flex: 0 0 2.33333333%;
    max-width: 2.33333333%;
  }

  tr:nth-child(odd) {
  background-color: #EBEFEF !important;
}

tr:hover {
  background-color:#d3d7d7 !important;
}
</style>