<template>
  <div>
    <div class="upbar col">
      <router-link v-if="displayLogo" :to="{name:'institutions.index'}"
                   class="institution-name-container col-1 h-100 text-center d-flex" :disabled="!displayLogo">
        <img class="bg-white rounded-circle sw-logo my-auto"
             src="../../assets/logo-superwyze-color.svg"/>
      </router-link>
      <div v-else></div>
      <div class="upbar-logo col-10 text-center my-auto">
        <img
            v-if="$router.currentRoute.name === 'institutions.index' || $router.currentRoute.name === 'institutions.create' || $router.currentRoute.name === 'institutions.edit'"
            src="../../assets/logo-superwyze-color.svg"/>
        <p v-else class="my-auto">{{ name }}</p>
      </div>
      <div class="my-auto">
        <font-awesome-icon v-if="!isFullScreen"
                           class="expand-icon fullscreen-icon"
                           :icon="expandIcon"
                           @click="goFullScreen"
                           size="lg"
        />
        <font-awesome-icon v-else
                           class="expand-icon fullscreen-icon"
                           :icon="compressIcon"
                           @click="goFullScreen"
                           size="lg"
        />
        <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none col-1" no-caret dropleft>
          <template #button-content>
            <img src="../../assets/user-logged-icon.svg" alt="account icon"/>
          </template>
          <b-dropdown-item @click="logout">Se deconnecter</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="downbar" v-if="displayParameterNavBar()">
      <template v-for="subRoute in subRoutesList">
        <router-link :key="subRoute.name"
                     v-if="userAccess && shouldDisplayLink(subRoute.name)"
                     :to="subRoute" tag="div" class="route-option"
                     :class="{ 'active-header': isActiveRoute(subRoute) }">
          <img :src="subRoute.meta.logo"/>
          {{ $t(subRoute.name) }}
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import store from "@/store";
import {RouterLinkStub as route} from "@vue/test-utils";
import {toggleFullScreen} from "@/utils/common";
import { faExpand } from '@fortawesome/free-solid-svg-icons'
import { faCompress } from '@fortawesome/free-solid-svg-icons'

export default {
  name: "LocalisationView",
  data: function () {
    return {
      expandIcon : faExpand,
      compressIcon: faCompress,
      isFullScreen: false,
      selectedInstitution: {},
      currentRouteName: "",
      allInstitutions: [],
      exludeRouteName: [
        'institutions.index'
      ],
      excludeSubRoute: [
        'access.denied'
      ],
      name: "",
      displayLogo: true,
    };
  },
  created(){
    this.checkFullScreen();
    document.addEventListener('fullscreenchange', this.checkFullScreen);
    document.addEventListener('mozfullscreenchange', this.checkFullScreen);
    document.addEventListener('webkitfullscreenchange', this.checkFullScreen);
    document.addEventListener('msfullscreenchange', this.checkFullScreen);
  },
  mounted() {
    this.currentRouteName = this.$route.name;
    this.shouldDisplayLogo()
    this.updateNameFromLocalStorage()
  },
  beforeDestroy() {
    document.removeEventListener('fullscreenchange', this.checkFullScreen);
    document.removeEventListener('mozfullscreenchange', this.checkFullScreen);
    document.removeEventListener('webkitfullscreenchange', this.checkFullScreen);
    document.removeEventListener('msfullscreenchange', this.checkFullScreen);
  },
  watch: {
    $route(to, _) {
      this.updateNameFromLocalStorage();
      this.currentRouteName = to.name;
      this.shouldDisplayLogo()
    },
  },
  computed: {
    route() {
      return route
    },
    store() {
      return store
    },
    subRoutesList() {
      return this.getChildRoutes();
    },
    userAccess() {
      return this.store.state.user.access
    }
  },
  methods: {
    faExpand() {
      return faExpand
    },
    updateNameFromLocalStorage() {
      this.name = store.state.institution.institutionName;
    },
    displayParameterNavBar() {
      return !(this.currentRouteName === 'institutions.edit' || this.currentRouteName === 'institutions.create');
    },
    getChildRoutes() {
      const institutionRoutes = router.options.routes.filter(route => route.path.startsWith("/institutions/"));

      if (institutionRoutes.length !== 1 || this.exludeRouteName.includes(this.$route.name)) {
        return [];
      }

      const mainChildren = institutionRoutes[0].children;

      let childRoutes = [];
      for (let child of mainChildren) {
        if (child.path && child.name && !child.path.includes("/")) {
          if (!this.excludeSubRoute.includes(child.name))
            childRoutes.push(child);
        }

        if (Array.isArray(child.children) && child.children.length) {
          const subChildrenWithEmptyPath = child.children.filter(subChild => subChild.path === "" && !subChild.name.includes('parameters'));
          childRoutes = childRoutes.concat(subChildrenWithEmptyPath);
        }
      }

      return childRoutes;
    },
    getRouteOptionClass(route) {
      return this.isCurrentRoute(route) ? "selected" : "";
    },
    isCurrentRoute(route) {
      return route.name === this.currentRouteName;
    },
    isActiveRoute(route) {
      const basePath = this.$route.path.split('/')[3];
      return route.name === this.$route.name || this.$route.name.includes(route.name) || basePath === route.path;
    },
    shouldDisplayLogo() {
      const listOfRoutes = ['institutions.index', 'institutions.create', 'institutions.edit']
      this.displayLogo = !listOfRoutes.includes(this.$route.name);
    },
    shouldDisplayLink(routeName) {
      const access = this.userAccess[routeName.split('.').at(-1)]
      if (access) {
        return access['access']
      }
    },
    logout() {
      this.$router.push({name: "login"})
    },
    goFullScreen(){
      this.isFullScreen = !this.isFullScreen
      toggleFullScreen();
    },
    checkFullScreen() {
      this.isFullScreen = document.fullscreenElement ||
                          document.mozFullScreenElement ||
                          document.webkitFullscreenElement ||
                          document.msFullscreenElement ? true : false;
    }
  },
};
</script>

<style>
@import "@/components/navbar/NavBar.scss";
.expand-icon:hover{
  cursor: pointer;
}

.expand-icon{
  color: white;
  position: relative;
  top: 0.5vh;
}
.fullscreen-icon {
  margin-bottom: 0.1em;
}
</style>
