import {authLogin, getUserAccessList} from "@/utils/auth";
import router from "@/router/index.js";
import {getRequest, putRequest} from "@/utils/utility";

const loggedOutState = {
    firstname: null,
    lastname: null,
    email: null,
    role: null,
    token: null,
    status: "loggedOut", //loggedOut, loggingIn, loggedId, loggingError
    error: null,
    access: null
};

const loadUserFromLocalStorage = () => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
        try {
            const user = JSON.parse(storedUser);
            return {...loggedOutState, ...user};
        } catch (error) {
            console.error("Error parsing user data from localStorage:", error);
        }
    }
    return loggedOutState;
};

const onLoginSuccess = (commit, user) => {
    getRequest(process.env.VUE_APP_URL_API + 'users/me', (response) => {
        commit("loginSuccess", {token: user, user: response.data});
    }, () => {
        console.error('Connexion failed')
    })
};

const userModule = {
    namespaced: true,
    state: loadUserFromLocalStorage(),
    actions: {
        login({commit}, {username, password}) {
            commit("loginRequest");
            authLogin(
                username,
                password,
                (user) => onLoginSuccess(commit, user),
                (error) => {
                    if (error.response.status === 511) {
                        router.push({name: 'legacyUser'});
                    } else {
                        commit("loginFailure", error);
                    }
                }
            );
        },
        resetPassword({commit}, {email}) {
            putRequest(process.env.VUE_APP_URL_API_USER + "/reset-password", {email: email}, () => {
                router.push({name: "confirmNewPassword"})
            }, (error) => {
                commit('resetFailure', error)
            })
        },
    },
    mutations: {
        loginRequest(state) {
            state.status = "loggingIn";
        },
        loginSuccess(state, userData) {
            state.status = "loggedIn";
            state.firstname = userData.user.name;
            state.email = userData.user.email;
            state.lastname = userData.user.lastname;
            state.role = userData.user.userRole;
            state.token = userData.token;
            localStorage.setItem("user", JSON.stringify(state));
            router.push({name: 'institutions.index'});
        },
        loginFailure(state, error) {
            state.status = "loggingError";
            state.error = error
        },
        logout(state) {
            localStorage.clear()
            state.firstname = null;
            state.lastname = null
            state.email = null
            state.role = null
            state.token = null
            state.status = "loggedOut"
            state.error = null
            state.access = null
        },
        checkToken(state, credentials) {
            state.email = credentials.email;
            state.token = credentials.token;
        },
        passwordCreationFailure(state, error) {
            state.status = 'passwordCreationError';
            state.error = error
        },
        updateUserRole(state, role){
            state.access = getUserAccessList(role)
            const storedUser = localStorage.getItem("user");
            if (storedUser) {
                const state = JSON.parse(storedUser);
                state.access = getUserAccessList(role);
                localStorage.setItem("user", JSON.stringify(state));
            }
        },
        resetFailure() {
            //todo: implement error handling
            return null
        }
    },
    modules: {},
};

export default userModule;
