<template>
  <div class="breadcrumb d-flex justify-content-center flex-wrap">
    <div
        v-for="(item, index) in items"
        :key="index"
        :class="{ active: item.active, disabled: item.disabled, 'first-link': index === 0 }"
        class="breadcrumb-list d-flex flex-row"
    >
      <router-link :to="item.to" v-if="!item.active && !item.disabled" class="d-flex flex-row align-items-center">
        <b-icon v-if="item.icon" :icon="item.icon"></b-icon>
        <inline-svg v-if="item.svg" :src="require(`@/assets/icons/breadcrumb/`+item.svg)" class="ml-3 mr-2" :class="['bc-link', item.active ? 'svg.active' : '', index === 0  ? 'first-svg' : '']" alt="SVG Icon"/>
        <span class="mr-3 bc-link">{{ item.name }}</span>
      </router-link>
      <span v-else :class="[item.active || item.disabled ? '' : 'bc-link', item.active ? 'breadcrumb-active' : '', item.disabled ? 'bc-disabled' : '' ]" class="d-flex flex-row align-items-center">
        <b-icon v-if="item.icon" :icon="item.icon"></b-icon>
        <inline-svg v-if="item.svg" class="ml-3 mr-2" :src="require(`@/assets/icons/breadcrumb/`+item.svg)" :class="[ item.disabled ? 'svg-disabled' : '', item.active ? 'svg.active' : '', index === 0  ? 'first-svg' : '' ]"  alt="SVG Icon"/>
        <span class="mr-3">{{ item.name }}</span>
      </span>
      <span class="divider" v-if="!dividerIcon && index < items.length - 1">{{ divider }}</span>
      <b-icon v-if="dividerIcon && index < items.length - 1" :icon="dividerIcon" class="divider"></b-icon>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg,
  },
  props: {
    items: {
      type: Array,
      required: true,
      name: {
        default: null,
        type: String,
        required: true
      },
      to: {
        required: true,
        type: String,
      },
      active: {
        default: false,
      },
      disabled: {
        default: false,
      },
      icon: {
        type: String,
        required: false,
      },
      svg: {
        type: String,
        required: false,
      }
    },
    divider: {
      default: null,
      type: String,
    },
    dividerIcon: {
      default: null,
      type: String,
    },
  },
};
</script>

<style scoped>
@import "BreadCrumb.scss";
</style>
