import {getRequest, postRequest} from "@/utils/utility";
import store from "@/store";

/**
 * retrieve institution by id
 */

let genericUrl = process.env.VUE_APP_URL_API_INSTITUTIONS

export const getInstitutionData = (to, from, next) => {
    const storedInstitution = store.state.institution;

    if (storedInstitution.buildings.length > 0 && parseInt(storedInstitution.id) === parseInt(to.params.institutionId)) {
        next();
    } else {
        getRequest(
            genericUrl + '/' + to.params.institutionId,
            (response) => {
                store.commit('institution/fillInstitution', response.data)
                next();
            },
            () => {
                next(false);
            },
        );
    }
};

export function getInstitutionServices(
    institutionId,
    successCallback,
    errorCallback = (error) => {
        console.error(error)
    }) {
    postRequest(
        genericUrl + '/' + institutionId + '/services',
        [],
        successCallback,
        errorCallback
    )
}

export function getInstitutionUserRole(
    institutionId,
    userId,
    successCallback,
    errorCallback = (error) => {
        console.error(error)
    }
    ) {
    getRequest(
        genericUrl + '/institutions/' + institutionId + '/users/' + userId + '/role',
        successCallback,
        errorCallback
    )
}