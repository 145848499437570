import store from "@/store";

export function haveAccessRights(to, from, next) {
    const access = store.state.user.access[to.name.split('.').at(-1)]
    if (access['access'] === true) {
        next();
    } else {
        next({name: 'access.denied', params: {institutionId: to.params.institutionId}})
    }
}

export function findCorrectRouteAfterInstitutionSelect() {
    const accessList = store.state.user.access;
    for (const access of Object.entries(accessList)) {
        if (access[1]['access']) {
            return access[1]['route'];
        }
    }
}
