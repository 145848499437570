const dashboardModule = {
    state: {
        selectedReport: null
    },
    mutations: {
        setSelectedReport(state, report) {
            state.selectedReport = report;
        }
    }
}

export default dashboardModule;