import { getRequest, postRequest, deleteRequest, putRequest } from "./utility";
import axios from "axios";

/**
 * Gets the list of users
 */
export function getUsers(successCallback, errorCallback) {
  const url = process.env.VUE_APP_URL_USERS;
  getRequest(axios, url, successCallback, errorCallback);
}

/**
 * Update a user
 */
export function updateUser(
  element,
  userId,
  data,
  successCallback,
  errorCallback
) {
  const url = process.env.VUE_APP_URL_USER + "/" + userId;
  postRequest(element.axios, url, data, successCallback, errorCallback);
}

/**
 * Delete a user
 */
export function deleteUser(element, userId, successCallback, errorCallback) {
  const url = process.env.VUE_APP_URL_USER + "/" + userId;
  deleteRequest(element.axios, url, null, successCallback, errorCallback);
}

/**
 * Create a user
 */
export function createUser(element, params, successCallback, errorCallback) {
  const url = process.env.VUE_APP_URL_USER;
  putRequest(element.axios, url, params, successCallback, errorCallback);
}

/**
 * Resend an invitation
 */
export function resendInvitation(
  element,
  email,
  successCallback,
  errorCallback
) {
  const url = process.env.VUE_APP_URL_USER + "/validation/email";
  const params = { email };
  postRequest(element.axios, url, params, successCallback, errorCallback);
}

/**
 * Lock or unlock a user
 */
export function lockUser(
  element,
  userId,
  toLock,
  successCallback,
  errorCallback
) {
  let url = process.env.VUE_APP_URL_USER + "/" + userId + "/";
  url += toLock ? "lock" : "unlock";
  postRequest(element.axios, url, null, successCallback, errorCallback);
}
