<template>
  <router-link :to="{name:route}">
    <button class="btn parameters-return text-uppercase">
      <b-icon-chevron-left></b-icon-chevron-left>
      retour
    </button>
  </router-link>
</template>
<script>
export default {
  props: {
    route: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped lang="scss">
@import "./ParametersReturn";
</style>