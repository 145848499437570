import moment from 'moment';

moment.updateLocale('fr', {
    relativeTime: {
        future: function (string) {
            return string === 'à l\'instant' ? string : 'dans ' + string;
        },
        past:  function (string) {
            return string === 'à l\'instant' ? string : 'il y a ' + string;
        },
        s: function (number) {
            if (number < 45) { 
                return 'à l\'instant';
            }
            return '%d secondes';
        },
        m: 'une minute',
        mm: '%d minutes',
        h: 'une heure',
        hh: '%d heures',
        d: 'un jour',
        dd: '%d jours',
        M: 'un mois',
        MM: '%d mois',
        y: 'un an',
        yy: '%d ans',
    },
})

export function getTimePassedFromNow(dateToCalculate) {
    let answer = null
    if (dateToCalculate) {
        const date = moment(dateToCalculate);
        answer = date.fromNow();
    }
    return answer;
}

export function customSort(propertiesMapping, a, b, key) {
    const propertyPath = propertiesMapping[key];
    if (!propertyPath) return 0;
    return compareValues(getNestedValue(a, propertyPath), getNestedValue(b, propertyPath));
}

function compareValues(valueA, valueB) {
    let valA = valueA ? valueA.toString().toLowerCase() : '';
    let valB = valueB ? valueB.toString().toLowerCase() : '';
    if (valA < valB) return -1;
    if (valA > valB) return 1;
    return 0;
}

function getNestedValue(obj, path) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}

export function naturalSort(array, keyOrIndex) {
    array.sort((a, b) => {
        let aValue = (typeof keyOrIndex === 'number') ? a[keyOrIndex] : a[keyOrIndex];
        let bValue = (typeof keyOrIndex === 'number') ? b[keyOrIndex] : b[keyOrIndex];
        return aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base' });
    });
}

export function toggleFullScreen() {
    if (document.fullScreenElement ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
            document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
            document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
    } else {
        if (document.cancelFullScreen) {
            document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        }
    }
}